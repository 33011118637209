import { useEffect } from "react";
import UsagePolicies from "../../Components/UsagePolicies";

const Hexacore = () => {
  useEffect(() => {
    document.title = "Hexacore";

    return () => {
      document.title = "Politicas de uso";
    };
  }, []);
  return (
    <UsagePolicies
      proyect="Hexacore"
      policies={[
        {
          title: "",
          description:
            "Hexacore es una aplicación orientada certificación a la autenticidad de productos",
        },
        {
          title: "Recopilación y uso de datos personales",
          description: `
            * Esta aplicación no recupera datos personales.
            `,
        },
        {
          title: "Permisos",
          description: `
          * Esta aplicación requiere de NFC
            `,
        },
        {
          title: "Privacidad de menores",
          description: `
          * Esta aplicación no está dirigida a ninguna persona inferior a los 13 años de edad. No se recopila información personal de niños en esta aplicación.
            `,
        },
      ]}
    />
  );
};

export default Hexacore;
