import React, { FC } from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { styled } from "@mui/system";

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#f8f8f8",
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: "bold",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: "white",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
}));

const StyledListItemTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: "bold",
}));

const StyledListItemText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
}));

type props = {
  proyect: string;
  policies: Array<{ title: string; description: string }>;
};
const UsagePolicies: FC<props> = ({ policies, proyect }) => (
  <StyledContainer maxWidth="md">
    <StyledTitle variant="h4">Políticas de Uso de {proyect}</StyledTitle>
    <List>
      {policies.map((policy, index) => (
        <StyledListItem key={index}>
          <ListItemText
            primary={
              <StyledListItemTitle variant="h6">
                {policy.title}
              </StyledListItemTitle>
            }
            secondary={
              <StyledListItemText variant="body2">
                {policy.description}
              </StyledListItemText>
            }
          />
        </StyledListItem>
      ))}
    </List>
  </StyledContainer>
);
export default UsagePolicies;
